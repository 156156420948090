.freecard {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
}

.freecardLeftHeader {
  color: #111827;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.freecardLeftSub {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
}

.bgBlue {
  background-color: rgb(79, 70, 229);
  color: #fff;
}

.colorGray900 {
  color: rgb(17, 24, 39);
}

.weekSpan {
  color: #111827;
  display: inline-block;
  padding: 5px 8px;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  height: 35px;
  line-height: 20px;
  padding-right: 5px;
}
.weekSpan1 {
  display: inline-block;
  padding: 5px;
  border-radius: 100%;
  border: 1px solid #ccc;
  cursor: pointer;
  height: 20px;
  width: 20px;
  line-height: 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.23);
  color: #fff;
  font-weight: 500;
}
.bg-gray-50 {
  background-color: rgb(249, 250, 251);
}
.bg-white {
  background-color: #fff;
}
.bg-gray-200 {
  background-color: rgb(229, 231, 235);
}
.bg-gray-100 {
  background-color: rgb(243, 244, 246);
}

.text-active {
  background: #cffafe;
  border-radius: 10px;
  color: #155e75;
  width: 40px;
  height: 20px;
  text-align: center;
  padding: 2px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.text-inactive {
  background: #f3f4f6;
  border-radius: 10px;
  color: #1f2937;
  width: 40px;
  height: 20px;
  text-align: center;
  padding: 2px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.dropBox:after {
  content: attr(data-attr);
  background-color: rgba(238, 238, 238);
  width: 100%;
  height: 100%;
  opacity: 0.9;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.freemiun-nav-bar {
  position: fixed;
  z-index: 1100;
  background: #ffffff;
  width: 100%;
  left: 0;
}

[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type='checkbox']:checked,
[type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type='radio'] {
  border-radius: 100%;
}

[type='radio'],
[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #0891b2;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}

[type='checkbox']:hover {
  cursor: pointer;
}

[type='checkbox']:checked {
  background-color: #0891b2;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type='checkbox']:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.w-\[-webkit-fill-available\] {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: -fill-available;
}

tbody tr:hover {
  background: #f9fafb;
}

.ripple {
  overflow: hidden;
}
.ripple:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #e5e7eb 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 0.7s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

*:focus {
  outline-width: 2px;
  outline-color: #0891b2;
}

* {
  scrollbar-color: #0891b2 #f3f4f6;
  scrollbar-width: thin;
}

/* 全局滚动条样式 */
::-webkit-scrollbar {
  width: 5px; /* 纵向滚动条*/
  height: 5px; /* 横向滚动条 */
  background-color: #fff;
}

/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f3f4f6;
}

/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #0891b2;
  border-radius: 10px;
}

.react-datepicker-popper div .react-datepicker {
  background-color: #ffffff;
  min-width: 300px;
  border: 1px solid #e5e7eb;
  /* overflow: auto; */
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  font-size: 16px;
}

.react-datepicker--time-only {
  min-width: 0 !important;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: -40px !important;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  border-bottom-color: #d1d5db !important;
}

.react-datepicker__navigation-icon {
  top: 8px !important;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: #ffffff !important;
}

.react-datepicker__header {
  border-radius: 10px 10px 0 0 !important;
  background-color: #ffffff !important;
  overflow: hidden;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-evenly;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 16px !important;
  font-weight: normal !important;
}

.react-datepicker__month {
  margin: 0.4rem 0px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 100% !important;
  background-color: #0891b2 !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: normal !important;
  color: #0891b2 !important;
}

.react-datepicker__day--selected {
  color: #ffffff !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 100% !important;
  background-color: rgba(13, 115, 143, 0.2) !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 30px !important;
  line-height: 30px !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: rgba(13, 115, 143, 0.2) !important;
}
