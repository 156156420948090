* {
  font-family: Poppins, Helvetica, sans-serif;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 13px !important;
  font-weight: 300;
  font-family: Poppins, Helvetica, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 隐藏视窗的滚动条 - body部分 */
/* body::-webkit-scrollbar {
  display: none;
} */

html {
  font-family: sans-serif;
  line-height: 1.15;
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

i {
  font-family: Poppins, Helvetica, sans-serif;
}

body {
  color: rgb(55, 53, 47);
  background: #efeff5;
}

:root {
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #5867dd;
  --secondary: #e1e1ef;
  --success: #1dc9b7;
  --info: #5578eb;
  --warning: #ffb822;
  --danger: #fd397a;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1399px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}

code {
  font-family: Poppins, Helvetica, sans-serif;
}

p {
  margin: 0;
}

.menu-button-menuContant::before {
  background: #06b6d4;
  top: -8px;
  position: absolute;
  display: block;
  height: 2px;
  min-height: 2px;
  content: '';
  border-radius: 2px;
}

.menu-button-menuContant::after {
  background: #06b6d4;
  bottom: -8px;
  position: absolute;
  display: block;
  height: 2px;
  min-height: 2px;
  content: '';
  border-radius: 2px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/* .fc-day-today > div > .fc-daygrid-day-top > a {
  color: white !important;
  width: 20px;
  height: 20px;
  background: #4f46e5;
  border-radius: 100%;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

input,
textarea,
.select__control > div {
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: medium !important;
  font-weight: 400 !important;
}

.react-datepicker-wrapper > div > div {
  padding: 0 8px;
  font-size: medium !important;
  font-weight: 400 !important;
}

input:focus-visible {
  border-color: #06b6d4 !important;
}

