#githubLink {
  position: absolute;
  right: 0;
  top: 12px;
  color: #2d99ff;
}

h1 {
  margin: 10px 0;
  font-size: 40px;
}

#loadingMessage {
  text-align: center;
  padding: 40px;
  background-color: #eee;
}

#canvas {
  width: 400px;
  height: 400px;
}

#output {
  margin-top: 20px;
  background: #eee;
  padding: 10px;
  padding-bottom: 0;
}

#output div {
  padding-bottom: 10px;
  word-wrap: break-word;
}

#noQRFound {
  text-align: center;
}
